import React from "react"
import '../../common/css/global.css'
import './tab.css'

const ClouddataContent = () => {
    return (
        <div className="tab-content">
            <h2 className="component-heading center">Hybrid Cloud Computing</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">Hybrid cloud refers to a mixed computing, storage, and services environment made up of on-premises infrastructure, private cloud services, and a public cloud—such as Amazon Web Services (AWS) or Microsoft Azure or Google Cloud with orchestration among the various platforms. Using a combination of public clouds, on-premises computing, and private clouds in your data centre means that you have a hybrid cloud infrastructure.</p>
                </div>
                <div className="tab-article">
                Techossy Solutions resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.
                </div>
                <div className="tab-image-banner">
                    <img src="images/sap.svg" alt="banner" />
                </div>
                <div className="service-content">
                    <p className="content-text">Hybrid cloud computing is about meeting the needs of your most important customers at any time, whether their needs are for speed, scale, agility or predictability. By extending infrastructure and applications across the private and public cloud with open hybrid cloud computing, you can provide consistent service levels to all users, no matter where they are or what they need.</p>
                </div>
        </div>
    )
}

export default ClouddataContent;