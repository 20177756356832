import React from "react"
import '../../common/css/global.css'
import './tab.css'

const DrugDataContent = () => {
    return (
        <div className="tab-content">
            <h2 className="component-heading center">Drug Database Analytics</h2>
                <div className="heading-dash"/>
                <div className="service-content">
                    <p className="content-text">Techossy foresees customer success through their products and services along with other biopharma players. We get into the customer strategy, align with their offerings and come up with better solutions. Our technologies ensure data solutions are comprehensive and make an insightful offering. Each insight derives from the depth and breadth of the drug development cycle, includes technology, drug phase, molecule structure, route of administration, disease analysis, multiple entities such as originator, licensor, licensee, technology partner, CRO, CDMO/CMO, clinical trial location, investigator, pivotal trial, patents, future events, drug price and reimbursement.
Customer service is our opportunity to fill gaps within the biopharmaceutical industry supply chain. We strive to create value addition to each industry player requirements and make more customer success stories.</p>
                </div>
                <div className="tab-article">
                Techossy Solutions resources expertise on Data Architecture, Big Data Architecture, Data migration, ETL development, Hybrid Cloud development, SAP ERP, Drug Database Analytics, Market Intelligence, UX/UI Development. We provide IT development strategies and solutions for small scale to large scale companies.
                </div>
                <div className="tab-image-banner">
                    <img src="images/drug-database-analytics.png" alt="banner" />
                </div>
                <div className="service-content">
                    <p className="content-text">Our leadership, scientific and technology team strives for continuous improvements in terms of client friendly data technologies, latest scientific innovations and access to the right analyst.
Query management team ensures a relevant reply within promised turnaround time.</p>
                </div>
        </div>
    )
}

export default DrugDataContent;