import React from "react"
import Tab from '../../components/tab'
import "./service.css"
const Services = ()=> {
    return (
        <div>
            <div className="page-banner">
                <img src="images/service-banner.png" alt="banner"/>
                <div className="page-caption">
                <h2>Services</h2>
                <p className="show-mobile">We help our customers' Competitive Intelligence in terms of Technology and Business to stay ahead with their customer experience. </p>
                <p className="hide-mobile">We help our customers' Competitive Intelligence in terms of Technology and Business to stay ahead with their customer experience, Techossy Solutions assists businesses in modernizing technology, reimagining processes, and transforming experiences. </p>
                </div>
            </div>
            <div className="service-tab-section">
                <Tab />
            </div>
        </div>
    )
}

export default Services;