import React from "react";
import "../../common/css/global.css"
import "./footer.css"
const Footer = () => {
    return (
        <div className="footer-component">
            <div className="footer-component-links">
                <div className="footer-comp-info footer-section">
                    <img src="images/logo-white.svg" alt="logo" />
                    <p className="footer-comp-info-text">We transform your business through custom solutions using cutting-edge tools and technology and Crafting Next-Gen Products to improve performance and streamline business operations</p>
                </div>
                <div className="footer-links footer-section">
                    <p className="footer-header">Quick links</p>
                    <p className="quick-link">
                        <span className="quick-link-img">
                            <img src="images/chevron_right.svg" alt="logo" />
                        </span>
                        <span className="quick-link-text">Home</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-img">
                            <img src="images/chevron_right.svg" alt="logo" />
                        </span>
                        <span className="quick-link-text">About Us</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-img">
                            <img src="images/chevron_right.svg" alt="logo" />
                        </span>
                        <span className="quick-link-text">Services</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-img">
                            <img src="images/chevron_right.svg" alt="logo" />
                        </span>
                        <span className="quick-link-text">Careers</span>
                    </p>
                    <p className="quick-link">
                        <span className="quick-link-img">
                            <img src="images/chevron_right.svg" alt="logo" />
                        </span>
                        <span className="quick-link-text">Contact Us</span>
                    </p>
                </div>
                <div className="footer-reach-us footer-section">
                <p className="footer-header">Reach Us</p>
                <p className="footer-comp-info-text">Techossy Solutions LLC
<span>5125 Saxon Way Cumming GA 30028</span>
<span>Email: info@techossy.com</span>
<span>Phone: 404-800-5522</span></p>
                </div>
                <div className="footer-connect footer-section">

                </div>
            </div>
            <div className="footer-copy-rights">
            Copyright © 2022. Powered by Techossy
            </div>
        </div>
    )
}

export default Footer;